import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'
import { startDKApp } from '@core/app'
import Accordion from './app/modules/accordion'
import Anchors from './app/modules/anchors'
import ConvertTags from './app/modules/convertTags'
import DialogCreator from './app/modules/dialog'
import Nav from './app/modules/nav'
import Videos from './app/modules/videos'

startDKApp(() => {
  Accordion()
  ConvertTags()
  Anchors()
  DialogCreator()
  Nav()
  Videos()
})

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: Number(process.env.SENTRY_SAMPLE_RATE),
})
